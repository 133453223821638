import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-intl"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"
import SignatureServices from "../components/Signature"

// Styles
import { Container, Row, Col, Button } from "react-bootstrap"
import * as styles from "../styles/templates/blog.module.scss"

export const query = graphql`
  query ($slug: String!) {
    contentfulSignatureServices(slug: { eq: $slug }) {
      slug
      title
      specialMonitoring
      image {
        gatsbyImageData(quality: 100, width: 400, layout: CONSTRAINED)
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 600, quality: 100)
            __typename
            url
          }
        }
      }
    }
    allContentfulLocation {
      edges {
        node {
          location
          slug
        }
      }
    }
  }
`

export default function SignatureService({ data }, props) {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  )
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, description, url } = node.data.target
        return (
          <a href={url} target="_blank">
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </a>
        )
      },
    },
  }
  const signatureService = data.contentfulSignatureServices
  const locations = data.allContentfulLocation.edges
  return (
    <Layout>
      <Title title={signatureService.title} />
      <SEO title={signatureService.title} />
      <Container fluid className="p-0">
        <Container className={`text-center px-5 ${styles.content}`}>
          <Row xs={1} sm={1} lg={2} className="align-items-center">
            <Col className="mx-auto">
              <GatsbyImage
                image={signatureService.image.gatsbyImageData}
                imgStyle={{ borderRadius: "6px" }}
                alt={signatureService.title}
              />
            </Col>
            <Col className="mx-auto text-start ">
              <h1 className="mt-5">{signatureService.title}</h1>
              {signatureService.title === "Free Vaccines" && (
                <p className=" justify-self-end mb-0">
                  <strong>• Qualify for CHDP Services •</strong>
                </p>
              )}

              {signatureService.specialMonitoring && (
                <p className=" justify-self-end mb-0">
                  <strong>• Special Monitoring •</strong>
                </p>
              )}
              <div className={`mt-3`}>
                {renderRichText(signatureService.description, options)}
              </div>

              {signatureService.slug === "circumcision" ? (
                <a href="/resources/circumcision.pdf" target="_blank">
                  <Button className="mt-2 me-2 py-3 btn-success">
                    View Brochure
                  </Button>
                </a>
              ) : (
                <>
                  <p>
                    You can schedule an appointment online, or call the office
                    nearest. After-hours and extended hours through Telehealth
                    are also available.
                  </p>

                  <p>
                    Our offices are located in{" "}
                    {locations
                      .sort((a, b) =>
                        a.node.location > b.node.location ? 1 : -1
                      )
                      .map(({ node }, idx) => (
                        <Link
                          key={idx}
                          to={`/locations/${node.slug}`}
                          className={styles.location}
                        >{`${idx === locations.length - 1 ? "and " : ""}${
                          node.location
                        }${idx !== locations.length - 1 ? ", " : "."}`}</Link>
                      ))}
                  </p>
                </>
              )}

              <Link to="/locations">
                <Button className="mt-2">Book an Appointment</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <hr />
      <SignatureServices page={true} />
    </Layout>
  )
}
